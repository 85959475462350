<template>
  <div class="pop" v-if="show">
    <div class="loadingWrap">
      <van-loading v-if="show" color="#fff"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'loading',
  components: {
  },
  data () {
    return {
      show: false
    }
  }
}
</script>

<style scoped lang='scss'>
  .pop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    background-color: rgba(0,0,0,0.2);
  }
  .loadingWrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
</style>
