import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    name: 'Home',
    path: '/',
    component: () => import('@/views/Home')
  },
  {
    name: 'goodsDetail',
    path: '/goodsDetail',
    component: () => import('@/views/goodsDetail/goodsDetail')
  },
  {
    name: 'backGoodsDetail',
    path: '/backGoodsDetail',
    component: () => import('@/views/backGoodsDetail/backGoodsDetail')
  },
  {
    name: 'confirmOrder',
    path: '/confirmOrder',
    component: () => import('@/views/confirmOrder/confirmOrder')
  },
  {
    name: 'confirmOrderDY',
    path: '/confirmOrderDY',
    component: () => import('@/views/confirmOrder/confirmOrderDY')
  },
  {
    name: 'confirmOrderDh',
    path: '/confirmOrderDh',
    component: () => import('@/views/confirmOrder/confirmOrderDh')
  },
  {
    name: 'thPay',
    path: '/thPay',
    component: () => import('@/views/thPay/thPay')
  },
  {
    name: 'ordQrCodePay',
    path: '/ordQrCodePay',
    component: () => import('@/views/ordQrCodePay/ordQrCodePay')
  },
  {
    name: 'clgPlusRule',
    path: '/clgPlusRule',
    component: () => import('@/views/clgPlusRule/clgPlusRule')
  },
  {
    name: 'tPay',
    path: '/tPay',
    component: () => import('@/views/tPay/tPay')
  }
]

const router = new VueRouter({
  routes
})

export default router
