import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import Loading from '@/common/loading.js'
// ---------------Vconsole---------------
// import Vconsole from 'vconsole'
// const vconsole = new Vconsole()
// Vue.use(vconsole)
// --------------------------------------
Vue.use(Loading)
Vue.use(Vant)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})
